import { Clue } from "./clue";
import { Row, RowState } from "./Row";
import { gameName, maxGuesses } from "./util";

export function Privacy() {
  return (
    <div className="App-about">
      <h2>
        Privacy Policy
      </h2>
      <p>
      Your privacy is crucially important to us at EmojiWordle.com. This Privacy Policy outlines our commitment to safeguarding your personal information while using our website. This Privacy Policy is applicable to https://emojiwordle.com/ (referred to as "us," "we," or "https://emojiwordle.com/"). Please take a moment to familiarize yourself with our practices regarding the collection, use, and disclosure of your information.
This Privacy Policy, combined with the Terms and Conditions posted on our website, establishes the general rules and policies governing your use of EmojiWordle.com. Depending on your activities on our website, you may be required to agree to additional terms and conditions.
      </p>
      <hr />
      <br/>
      <h3>
      Website Visitors
      </h3>
      <p>
      Similar to most website operators, EmojiWordle.com collects non-personally-identifying information, such as browser type, language preference, referring site, and the date and time of each visitor request. This information is collected to better understand how visitors use our website. Occasionally, EmojiWordle.com may release non-personally-identifying information in aggregate form, such as publishing reports on trends in website usage.

EmojiWordle.com also collects potentially personally-identifying information, including Internet Protocol (IP) addresses, for logged-in users and users leaving comments on https://emojiwordle.com/ blog posts. We disclose logged-in user and commenter IP addresses only under the same circumstances as we use and disclose personally-identifying information, as described below.
      </p>
      <hr />

     
      <br/>
      <h3>
      Security
      </h3>
      <p>
      We prioritize the security of your Personal Information; however, it's essential to note that no method of transmission over the Internet or electronic storage is 100% secure. While we employ commercially acceptable means to protect your Personal Information, we cannot guarantee absolute security.</p>
      <hr />

      
      <br/>
      <h3>
      Advertisements
      </h3>
      <p>
      We prioritize the security of your Personal Information; however, it's essential to note that no method of transmission over the Internet or electronic storage is 100% secure. While we employ commercially acceptable means to protect your Personal Information, we cannot guarantee absolute security.
      </p>
      <hr />


     
      <br/>
      <h3>
      Links To External Sites
      </h3>
      <p>
      Our Service may include links to external sites not operated by us. We recommend reviewing the Privacy Policy and terms and conditions of every site you visit, as we have no control over, and assume no responsibility for, the content, privacy policies, or practices of third-party sites, products, or services.      </p>
      <hr />

     
      <br/>
      <h3>
      EmojiWordle.com uses Google AdWords for remarketing
      </h3>
      <p>
      https://emojiwordle.com/ employs remarketing services to advertise on third-party websites, including Google, to previous visitors to our site. This may involve advertising to previous visitors who haven't completed a specific task on our site, such as using a contact form to make an inquiry. Third-party vendors, like Google, use cookies to serve ads based on past visits. Any data collected will be used in accordance with our privacy policy and Google's privacy policy. You can manage Google advertising preferences using the Google Ad Preferences page and opt out of interest-based advertising via cookie settings or a browser plugin.
      </p>
      <hr />

      
      <br/>
      <h3>
      Protection of Certain Personally-Identifying Information
      </h3>
      <p>
      EmojiWordle.com discloses potentially personally-identifying and personally-identifying information only to employees, contractors, and affiliated organizations that require such information to process it on our behalf or to provide services available at EmojiWordle.com. Some of these entities may be located outside your home country, and by using our website, you consent to the transfer of such information to them. EmojiWordle.com will not rent or sell potentially personally-identifying and personally-identifying information to anyone. Disclosure of such information may occur in response to a subpoena, court order, or governmental request, or when we believe in good faith that disclosure is necessary to protect our property, rights, or the public.
      </p>
      <br/>
      <p>
      If you're a registered user of https://emojiwordle.com/ and have provided your email address, we may occasionally email you to inform you about new features, request feedback, or provide updates. We primarily use our blog for such communications, keeping emails to a minimum. Requests sent to us may be published to clarify or respond to your request and support other users. EmojiWordle.com takes reasonable measures to protect against unauthorized access, use, alteration, or destruction of potentially personally-identifying and personally-identifying information.
      </p>
      <hr />


     
      <br/>
      <h3>
      Aggregated Statistics
      </h3>
      <p>
      EmojiWordle.com may collect statistics about visitor behavior on its website, which may be displayed publicly or provided to others. However, personally-identifying information will not be disclosed.
      </p>
      <hr />


      
      <br/>
      <h3>
      Affiliate Disclosure
      </h3>
      <p>
      This site uses affiliate links and may earn a commission from certain links. This does not impact your purchases or the price you pay.
      </p>
      <hr />

      
      <br/>
      <h3>
      Cookies
      </h3>
      <p>
      To enhance your online experience, EmojiWordle.com uses "Cookies" and similar technologies provided by others to display personalized content, appropriate advertising, and store preferences on your computer. Cookies are strings of information stored on a visitor's computer, provided to the website each time the visitor returns. If you prefer not to have cookies on your computer, adjust your browser settings accordingly, though this may affect certain features of EmojiWordle.com's websites.
      </p>
      <hr />

    
      <br/>
      <h3>
      Privacy Policy Changes
      </h3>
      <p>
      While most changes are likely to be minor, EmojiWordle.com may update its Privacy Policy from time to time at its sole discretion. We encourage visitors to check this page frequently for any changes. Continued use of this site after changes to the Privacy Policy constitutes acceptance of such changes.
      </p>
      <hr />

      
      <br/>
      <h3>
      Last updated 2024.01.13
      EmojiWordle.com © 2024
      </h3>
      <p>
      While most changes are likely to be minor, EmojiWordle.com may update its Privacy Policy from time to time at its sole discretion. We encourage visitors to check this page frequently for any changes. Continued use of this site after changes to the Privacy Policy constitutes acceptance of such changes.
      </p>
      <hr />

      
      <br/>
      EmojiWordle.com is not affiliated with "Wordle" by NYTimes in any way.
      <p>
      If you have any questions or concerns about our Privacy Policy, please contact us at 6sivaprasath@gmail.com.
      </p>
      <hr />


      <h4>
      EmojiWordle.com is not affiliated with "Wordle" by NYTimes in any way.
      </h4>
      
    </div>
  );
}
