export const config={
    firebaseConfig:{
        apiKey: "AIzaSyAiLgI50_Thf9awkRE3QiLNhc-F3v2yTXc",
        authDomain: "ew-auth-43660.firebaseapp.com",
        projectId: "ew-auth-43660",
        storageBucket: "ew-auth-43660.appspot.com",
        messagingSenderId: "122477028573",
        appId: "1:122477028573:web:cf6475e76388916d806734",
        measurementId: "G-73B3GV0BLK"
    }
};