// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAiLgI50_Thf9awkRE3QiLNhc-F3v2yTXc",
  authDomain: "ew-auth-43660.firebaseapp.com",
  projectId: "ew-auth-43660",
  storageBucket: "ew-auth-43660.appspot.com",
  messagingSenderId: "122477028573",
  appId: "1:122477028573:web:cf6475e76388916d806734",
  measurementId: "G-73B3GV0BLK"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
